<template>
  <h5 class="q-ma-md text-center">{{ $t("my_nfts") }}</h5>
  <!-- NFT List -->
  <div class="q-pa-md row my-list">
    <div
      v-for="k in mynfts"
      :key="k.id"
      class="q-pa-md col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
    >
      <q-card class="my-card">
        <q-img
          :ratio="1"
          class="my-img q-pa-md"
          :src="k.data.image"
          @click="show_image(k)"
        />

        <q-card-section>
          <div class="text-h6">
            <span class="text-blue">NFT {{ k.tokenid }} </span> of
            {{ k.data.count }}
          </div>
          <div class="text-h6">
            {{ k.data.name }}
          </div>
          <!-- <a
            class="my-link text-body"
            :href="'https://mumbai.polygonscan.com/address/' + k.id"
          >
            {{k.id}}
          </a> -->
          <div v-if="k.tid" class="text-body2">
            TID: 
            <a :href="'https://opensea.io/assets/matic/' + k.contract + '/' + k.tid">{{ k.tid.substring(0, 15) + "..." + k.tid.substring(70) }}</a>
          </div>

          <div class="text-body2">
            {{ pdate(k.created_at) }}
          </div>

          <div class="text-body2">{{ $t("price") }}: ${{ k.data.price }}</div>
          <!-- <q-checkbox
            class="q-ml-none"
            @click="set_show(k)"
            v-model="k.show"
            :label="$t('show_on_profile')"
          /> -->
        </q-card-section>

        <q-card-section class="q-pt-none text-center">
          <q-btn
            class="q-ma-md"
            color="blue"
            :label="$t('view_origin')"
            @click="show_nft(k.contract)"
          />
          <q-btn
            v-if="false"
            class="q-ma-sm"
            color="blue"
            :label="$t('transfer')"
            @click="
              cur_nft = k;
              win_transfer = true;
            "
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
  <q-dialog v-model="dialog" persistent class="mydialog">
    <q-card class="mycard">
      <div class="q-mp-lg absolute-top-right">
        <q-icon
          class="myclose"
          size="48px"
          name="close"
          color="white"
          @click="dialog = false"
        />
      </div>
      <q-card-section class="q-ma-lg">
        <a :href="cur_token_image" download="token-image">
          <div class="frame">
            <div class="mat">
              <div class="art">
                <q-img :ratio="1" :src="cur_token_image" />
              </div>
            </div>
          </div>
        </a>
      </q-card-section>
    </q-card>
  </q-dialog>

  <q-dialog v-model="win_transfer" persistent>
    <q-card style="min-width: 350px">
      <q-card-section> Transfer NFT </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input
          dense
          v-model="to_addr"
          autofocus
          @keyup.enter="prompt = false"
          label="Address (0x)"
          :rules="[(val) => !!val || 'Field is required']"
        />
        <q-input
          dense
          v-model="amount"
          autofocus
          @keyup.enter="prompt = false"
          label="Amount"
          :rules="[(val) => !!val || 'Field is required']"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('cancel')" v-close-popup />
        <q-btn
          flat
          :label="$t('transfer')"
          v-close-popup
          @click="transfer(cur_nft)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="sass" scoped>
.myclose
  cursor: pointer

.mycard
  width: 1024px
  max-width: 90vw
  background-color: rgba(255,255,255,0)
  box-shadow: 0 0 0 0 rgba(255,255,255,0)
.frame
  position: relative
  width: 100%
  padding-bottom: 100%
  background: black
  box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.3)

.mat
  position: absolute
  background: white
  top: 2.5%
  bottom: 2.5%
  left: 2.5%
  right: 2.5%
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5) inset

.art
  position: absolute
  top: 5%
  // bottom: 5%
  left: 5%
  right: 5%
  img
    width: 100%
.art::after
  content: ''
  display: block
  position: absolute
  top: 0
  width: 100%
  height: 100%
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5) inset

.my-link
  display: inline-block
  width: 100%
  overflow: hidden
  text-overflow: ellipsis

.my-list
  margin-left: 0rem
  margin-right: 0rem

.myavatar
  position: absolute
  left: 50%
  margin-left: -100px
  bottom: -100px

.myname
  margin-top: 120px

.my-card
  .my-card-btn
    width: 100%
  .my-img
    cursor: pointer
</style>

<script>
import router from "@/router";
import { ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import { useQuasar } from "quasar";
import { APIURL } from "../web3";
import axios from "axios";

export default {
  name: "MyTokens",
  setup() {
    const mynfts = ref([]);
    const user = s.auth.user();
    onBeforeMount(async () => {
      let { data: tokens, error } = await s
        .from("tokens")
        .select("*")
        .eq("owner", user.user_metadata.wallet)
        .order("created_at", { ascending: false });
      console.log(tokens);
      tokens.forEach((t) => {
        if (t.show == null) {
          t.show = false;
        }
      });
      mynfts.value = tokens;
    });
    return {
      user,
      q: useQuasar(),
      cur_token_image: ref(""),
      dialog: ref(false),
      maximized_toggle: ref(true),
      mynfts,
      win_transfer: ref(false),
      cur_nft: ref(null),
      to_addr: ref(""),
      amount: ref(1),
    };
  },
  methods: {
    async set_show(k) {
      console.log(k);
      let data = await s
        .from("tokens")
        .update({ show: k.show })
        .match({ id: k.id });
      if (data["error"]) {
        console.log(data["error"]);
      } else {
        console.log(data);
        this.q.notify("Set token " + k.data.name + " show status success!");
      }
    },
    show_image(k) {
      this.dialog = true;
      this.cur_token_image = k.data.image;
    },
    show_nft(id) {
      router.push("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("en-US").format(
        date
      );
    },
    async transfer(nft) {
      const to_addr = this.to_addr;

      // Insert TXN record
      const record = {
        token: nft.id,
        ufrom: this.user.id,
        contract: nft.contract,
        tid: nft.tid,
        from: this.user.user_metadata.wallet,
        to: to_addr,
        price: 0,
        type: "TRANSFER",
        status: "PENDING",
      };
      console.log(record);
      const { data, error } = await s.from("txns").insert([record]);
      if (error) {
        console.log(error);
        this.q.notify("Create transfer transaction failed!" + error);
        return;
      } else {
        console.log("Insert txn success:", data);
      }

      let txn = data[0];
      // transfer exist token to user
      this.q.loading.show({
        message:
          "Transfer NFT, it will take several minutes, please don't leave the page!",
      });
      const url = APIURL + "/i/transfer";

      const params = {
        to: txn.to,
        contract: txn.contract,
        amount: this.amount,
        tid: txn.tid,
        uuid: this.user.id,
      };

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log("transfer token req:", options);
      axios(options)
        .then(async (res) => {
          console.log("transfer api response:", res);
          if (res.data.blockHash != undefined) {
            // record transaction record
            console.log("blockchain txn receipt:", res);
            {
              const { data, error } = await s
                .from("txns")
                .update({
                  txn: res,
                  status: "DONE",
                })
                .match({ id: txn.id });
              if (error) {
                console.log("Set txn blockchain txn error.", error);
                alert(error.message);
              } else {
                console.log("Set txn blockchain txn success.", data);
                this.q.notify(
                  "Transfer success! blockchain transaction was executed!"
                );
              }
            }
          } else {
            console.log("ERR:blockchain transaction");
            this.q.notify("ERR:blockchain transaction");
          }
        })
        .then(() => {
          this.q.loading.hide();
          this.q.notify("Transfer NFT to " + this.to_addr + " SUCCESS!");
        })
        .catch((e) => {
          this.q.notify(e.toString());
          this.q.loading.hide();
        });
    },
  },
};
</script>